import React from 'react'

import Link from '../utils/link'

import './ErrorContainer.scss'

const ErrorContainer = () => {
  return (
    <section className="content">
      <div className="container">
        <div className="error-container">
          <div>
            <h1>404 Error</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Link to="/" className="error-container__link">
              Back home
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ErrorContainer
