import React from 'react'
import ErrorContainer from '../components/ErrorContainer'

import SEO from '../components/seo'
import Header from '../components/header'
import Footer from '../components/footer'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Header isDark />
    <ErrorContainer />
    <Footer />
  </>
)

export default NotFoundPage
